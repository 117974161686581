import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { TPermissionsEnum, TUserProfile } from './types'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function jsonStringifyFormData(formData: FormData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const object: { [key: string]: any } = {}
    formData.forEach((value, key) => (object[key] = value))
    const json = JSON.stringify(object)
    return json
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

type TUserWithName = {
    username?: string
    last_name?: string
    first_name?: string
    middle_name?: string
}

export const getFullName = (user: TUserWithName) => {
    if (!user) {
        return
    }

    const nameArray = []

    if (user.last_name) {
        nameArray.push(user.last_name)
    }

    if (user.first_name) {
        nameArray.push(user.first_name)
        if (user.middle_name) {
            nameArray.push(user.middle_name)
        }
    }

    return nameArray.length > 0 ? nameArray.join(' ') : user.username
}

export const checkSinglePermission = (
    userProfile: TUserProfile,
    perm: TPermissionsEnum
) => {
    return !!userProfile && userProfile.permissions.has(perm)
}

export const checkSomePermission = (
    userProfile: TUserProfile,
    perms: TPermissionsEnum[]
) => {
    return (
        !!userProfile && perms.some((perm) => userProfile.permissions.has(perm))
    )
}

export const filterByPermissions = (
    userProfile: TUserProfile,
    items: { permissions?: TPermissionsEnum[] }[]
) => {
    if (!userProfile) []

    if (userProfile.is_superuser) return items

    return items.filter((item) => {
        if (item.permissions) {
            return item.permissions.some((perm) =>
                userProfile.permissions.has(perm)
            )
        } else {
            return true
        }
    })
}
